// Body
$body-bg                : #fff;
$body-color             : #151515;

$white                  : #fff;
$grey-light             : #f5f5f5;
$gray                   : #F6F6F6;
$gray-dark              : #E9E9E9;
$gray-veryDark          : #111111;

// Typography
$font-family-sans-serif : "futura-pt", sans-serif;
$font-weight-base       : 300;
$font-size-base         : 1.25rem;

$primary                : #e51e21;
$secondary              : #3127C0;
$green                  : #5CAF4D;

$fa-font-path           : "../../../fonts" !default;

$enable-rounded         : false;

$headings-font-weight   : 600;
$h1-font-size           : 32px;
$h2-font-size           : 26px;

$btn-border-radius      : 5px;
$btn-font-weight        : 500;
$btn-font-size          : 20px;

$btn-close-width        : 11px;
