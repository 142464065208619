a {
    transition : color .3s;

    &.btn {
        &:after {
            content : none;
        }
    }
}

h1 {
    text-transform : uppercase;
    margin-bottom  : 0;
    line-height    : 1;
}

h2 {
    margin-bottom  : 0;
    line-height    : 1;
    text-transform : uppercase;
}

.btn {
    border-radius : 5px;
    padding       : 4px 62px;
    border-width  : 2px;
    min-width     : 255px;

    @include media-breakpoint-down(lg) {
        padding-left  : 25px;
        padding-right : 25px;
    }

    &:hover,
    &:focus {
        box-shadow : none !important;
    }
}

.btn-primary {
    background   : $gray-veryDark;
    border-color : $gray-veryDark;

    &:focus,
    &:hover {
        background : $white;
        color      : $gray-veryDark;
    }
}

.btn-secondary {
    &:hover {
        background : $white;
        color      : $secondary;
    }
}

#app {
    min-height     : 100vh;
    padding-bottom : 200px;
    position       : relative;
}

footer {
    position         : absolute;
    bottom           : 0;
    left             : 0;
    right            : 0;
    width            : 100%;
    background-color : $grey-light;
    padding-top      : 30px;
    padding-bottom   : 30px;

    .copyright {
        padding-left : 11px;
        text-align   : left;
        font-size    : 13px;
    }

    ul {
        text-align    : right;
        margin-bottom : 0;
        padding-left  : 0;

        @include media-breakpoint-down(md) {
            text-align : left;
        }

        li {
            display       : inline-block;
            padding-right : 6px;
            margin-left   : 50px;

            @include media-breakpoint-down(lg) {
                margin-left : 10px;
            }

            &:first-of-type {
                margin-left : 0;
            }

            &:last-child {
                border-right : none;
            }

            a {
                color           : black;
                font-size       : 13px;
                text-decoration : none;

                &:hover {
                    color : $primary;
                }
            }
        }
    }
}

#lang-switch {
    top   : 0;
    right : $grid-gutter-width / 2;

    @include media-breakpoint-down(md) {
        display : none;
    }

    a {
        &.active {
            text-decoration : underline;
        }
    }
}

#navbarSupportedContent {
    align-self : flex-end;

    .navbar-backend & {
        align-self : center;
    }

    a {
        margin-left : 26px;

        .navbar-backend & {
            margin-left : 78px;

            @include media-breakpoint-down(lg) {
                margin-left : 40px;
            }
        }

        @include media-breakpoint-down(lg) {
            margin-left : 30px;
        }
    }
}

nav {
    background-color : #fff;
}

.navbar-light.navbar-backend .navbar-nav {
    .nav-link {
        color : $body-color;

        &:hover,
        &.active {
            color : $secondary;
        }
    }
}

.navbar-light .navbar-nav {
    .nav-link {
        transition : color .3s;

        &:hover,
        &.active {
            color : $secondary;
        }
    }

    a {
        font-size : 16px;
    }
}

main {
    ul {
        margin-bottom : 0;
        list-style    : none;
        padding-left  : 20px;

        li {
            position : relative;

            &:before {
                background-image    : url("../img/arrow-right.svg");
                content             : "";
                position            : absolute;
                width               : 8px;
                height              : 10px;
                background-repeat   : no-repeat;
                background-size     : 100%;
                background-position : center;
                left                : -15px;
                top                 : 10px;
            }

            ul {
                padding-left : 15px;

                li {
                    &:before {
                        background-image : url("../img/arrow-single-right.svg");
                        width            : 6px;
                    }
                }
            }
        }
    }

    a {
        text-decoration : none;
        position        : relative;

        &:after {
            content          : "";
            position         : absolute;
            left             : 0;
            bottom           : 0;
            height           : 1px;
            width            : 0;
            background-color : $primary;
            transition       : width .3s;
        }

        &:hover {
            color : $primary;

            &:after {
                width : 100%;
            }
        }
    }
}

.form-group {
    margin-bottom : 15px;
}

.card {
    &.active {
        .card-header {
            background-color : $primary;
            color            : #fff;
        }
    }
}

.payment-chooser {
    position         : relative;
    padding-top      : 100%;
    background-color : #fff;

    input {
        position   : absolute;
        left       : 50%;
        transform  : translateY(-50%);
        appearance : none;
        width      : 1px;
        height     : 1px;
        opacity    : 0;
    }
}

.payment-chooser-inner {
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    display         : flex;
    justify-content : center;
    align-items     : center;
    border          : 3px solid transparent;
    transition      : border-color .3s;
    cursor          : pointer;

    .disabled & {
        cursor           : not-allowed;
        background-color : #cfcdcd;
    }

    input:checked + & {
        border-color : $primary;
    }
}

.navbar-nav {
    font-weight : 400;
}

.navbar-lang {
    a {
        color           : $body-color;
        text-decoration : none;
        margin-left     : 50px;

        &:hover {
            color : $secondary;
        }
    }
}

.nav-item {
    &.hide-desktop {
        display : none;

        @include media-breakpoint-down(md) {
            display : initial;
        }
    }

    &:last-of-type {
        a {
            padding-right : 0 !important;
        }
    }
}

.navbar {
    box-shadow : rgb(0 0 0 / 7%) 0 3px 15px 0;
}

.box-wrapper {
    padding          : 30px 45px;
    background-color : $gray;
    border-radius    : 10px;
}

.bill-row,
.user-row {
    display : flex;

    .users-headlines & {
        border-bottom : none;
    }

    @include media-breakpoint-down(md) {
        display : block;
    }
}

.user-row {
    padding-left    : 29px;
    padding-right   : 29px;
    background      : $white;
    justify-content : space-between;
    color           : #151515;

    .users-headlines & {
        background : $gray-dark;
    }

    &:nth-of-type(even) {
        background : $gray;
    }
}

.bill-col,
.user-col {
    display         : flex;
    flex-direction  : column;
    align-items     : flex-start;
    justify-content : center;

    @include media-breakpoint-down(md) {
        flex-direction : column;
        align-items    : flex-start;
    }

    .bill-headlines &,
    .users-headlines & {
        font-weight : 600;
    }
}

.bill-col {
    flex-direction  : row;
    margin-bottom   : 30px;
    justify-content : flex-start;

    @include media-breakpoint-down(sm) {
        display : grid;
        gap     : 15px;

        .btn {
            width : 100%;
        }
    }
}

.user-col-actions {
    display        : flex;
    flex-direction : row;
    column-gap     : 115px;
    line-height    : 1;

    @include media-breakpoint-down(lg) {
        column-gap : 15px;
    }

    &.user-col-actions-button {
        column-gap : 20px;

        .btn {
            padding         : 4px 10px;
            width           : 150px;
            min-width       : 150px;
            display         : flex;
            align-items     : center;
            column-gap      : 10px;
            justify-content : center;
            line-height     : 1;

            i {
                font-size : 15px;
            }
        }

        .btn-secondary {
            &:hover {
                i {
                    color : $secondary !important;
                }
            }
        }

        .btn-outline-secondary {
            &:hover {
                i {
                    color : $white !important;
                }
            }
        }
    }

    i {
        font-size  : 14px;
        transition : color .3s;
    }

    &.user-col-actions-column {
        flex-direction : column;
        column-gap     : 0;
        row-gap        : 10px;
    }
}

.user-col-action {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    column-gap     : 10px;
    color          : #151515;
    line-height    : 1;

    &:hover {
        color : $gray-veryDark;
    }

    i {
        color : $secondary;
    }
}

.users-headlines {
    background              : $gray-dark;
    border-top-left-radius  : 10px;
    border-top-right-radius : 10px;
    font-size               : 26px;
    line-height             : 1;
}

.user-row-danger {
    background : lighten($primary, 30%);
}

.user-row-bill {
    background : #FAF8E2 !important;
}

.user-row-success {
    background : lighten($success, 30%);
}

.bill-col {
    &:nth-child(1) {
        flex : 0 0 20%;
    }

    &:nth-child(2) {
        flex : 0 0 20%;
    }

    &:nth-child(3) {
        flex : 0 0 60%;

        @include media-breakpoint-down(md) {
            flex-direction : row;
        }
    }
}

.table-customer-dashboard {
    overflow-x : scroll;
}

.user-col {
    line-height    : 1.1;
    padding-bottom : 10px;
    padding-top    : 10px;

    .user-row:nth-of-type(even) & {
        background : $gray;
    }

    .users-headlines & {
        background     : $gray-dark;
        padding-bottom : 15px;
        padding-top    : 15px;
    }

    .user-row-bill & {
        background : #FAF8E2 !important;
    }

    .table-customer-bill-dashboard & {
        flex : 0 0 25%!important;
    }

    &:nth-child(1) {
        .table-customer-bill-dashboard & {
            flex : 0 0 25%;
        }

        .table-customer-dashboard & {
            flex      : 0 0 33%;
            min-width : 403px;
        }

        .table-admin-dashboard & {
            flex : 0 0 40%;
        }
    }

    &:nth-child(2) {
        .table-customer-bill-dashboard & {
            flex : 0 0 50%;

            @include media-breakpoint-down(lg) {
                flex : 0 0 25%;
            }
        }

        .table-customer-dashboard & {
            flex      : 0 0 15%;
            min-width : 185px;
        }

        .table-admin-dashboard & {
            flex : 0 0 30%;
        }
    }

    &:nth-child(3) {
        .table-customer-bill-dashboard & {
            flex : 0 0 25%;

            @include media-breakpoint-down(lg) {
                flex : 0 0 50%;
            }
        }

        .table-customer-dashboard & {
            flex      : 0 0 24%;
            min-width : 293px;
        }

        .table-admin-dashboard & {
            flex : 0 0 30%;
        }
    }

    &:nth-child(4) {
        .table-customer-dashboard & {
            flex      : 0 0 12%;
            min-width : 146px;
        }
    }

    &:nth-child(5) {
        .table-customer-dashboard & {
            flex      : 0 0 16%;
            min-width : 198px;
        }
    }

    .btn {
        font-size : 18px;
    }

    form {
        line-height : 1;
    }
}

.bill-col-label,
.user-col-label {
    display : none;

    @include media-breakpoint-down(md) {
        display : block;
    }
}

.bill-headlines,
.user-headlines {
    @include media-breakpoint-down(md) {
        display : none;
    }
}

.package-wrapper {
    border         : 2px solid #000;
    border-radius  : 30px;
    padding        : 55px 30px 0;
    position       : relative;
    font-size      : 20px;
    height         : 100%;
    display        : flex;
    flex-direction : column;

    &.additional-package {
        border-color : #696969;
        padding-top  : 45px;
    }

    .current-package & {
        border-color : $secondary;
    }

    strong {
        font-weight : 600;
    }

    ul {
        margin-top    : 15px;
        margin-bottom : 30px;
        flex-grow     : 1;
    }


}

.package-action {
    margin-left                : -30px;
    margin-right               : -30px;
    background-color           : #000;
    font-weight                : 500;
    font-size                  : 20px;
    text-transform             : uppercase;
    text-align                 : center;
    border-bottom-left-radius  : 25px;
    border-bottom-right-radius : 25px;
    padding                    : 7px 0;

    .additional-package & {
        background-color : #696969;

        .current-package & {
            pointer-events : initial;
        }
    }

    .current-package & {
        background-color : $secondary;
        pointer-events   : none;
    }

    a {
        color   : #fff;
        display : block;

        &:after {
            content : none;
        }

        &:hover {
            color : #fff;
        }
    }
}

.package-name {
    font-weight    : 600;
    text-transform : uppercase;
    text-align     : center;
    font-size      : 26px;
}

.package-icon {
    position         : absolute;
    left             : 50%;
    transform        : translateX(-50%);
    top              : -55px;
    font-size        : 70px;
    background-color : #fff;
    padding-left     : 10px;
    padding-right    : 10px;

    .additional-package & {
        color     : #696969;
        font-size : 55px;
        top       : -40px;
    }

    .current-package & {
        color : $secondary;
    }
}

.home-package {
    display : flex;

    @include media-breakpoint-down(lg) {
        justify-content : center;
    }
}

.font-20 {
    font-size : 20px;
}

.font-40 {
    font-size : 40px;
}

.font-weight-500 {
    font-weight : 500;
}

.enterprise-link {
    font-size   : 20px;
    font-weight : 600;
}

.no-underline {
    &:after {
        content : none;
    }
}

.dropdown-menu {
    li {
        &:before {
            content : none;
        }
    }
}

.form-switch .form-check-input {
    border-radius : 10px;
}

.form-check-input {
    &:focus {
        box-shadow : none !important;
    }

    &:checked {
        background-color : $secondary;
        border-color     : $secondary;
    }
}

.modal-dialog {
    max-width : 650px;
}

.modal-content {
    border        : none;
    border-radius : 10px;
}

.modal-header {
    padding : 40px 40px 20px;
    border  : none;

    .btn-close {
        position : absolute;
        top      : 30px;
        right    : 30px;
        opacity  : 1;
        width    : 11px;
        height   : 11px;
    }
}

.modal-title {
    font-size      : 32px;
    text-transform : uppercase;
    line-height    : 1;
}

.modal-body {
    padding : 0 40px 15px;

    .form-select {
        border-radius    : 5px;
        border           : none;
        background-color : $gray;
        height           : 50px;
    }

    textarea {
        background-color : $gray;

        &:focus {
            background-color : $gray;
        }
    }

    .input-group {
        border : none;
        height : 50px;

        > .form-control {
            background-color           : $gray;
            border                     : none;
            border-top-right-radius    : 5px;
            border-bottom-right-radius : 5px;

            &::-webkit-file-upload-button {
                background-color          : $gray-dark;
                border                    : none;
                border-top-left-radius    : 5px;
                border-bottom-left-radius : 5px;
                padding-left              : 18px;
                padding-right             : 18px;
                margin-inline-end         : 24px;
                height                    : 50px;
            }
        }
    }
}

.modal-footer {
    padding : 15px 40px 40px;
    border  : none;
}

.form-label {
    font-weight : 600;
}

.form-control,
.form-select {
    background-color : $white;
    border           : none;
    border-radius    : 5px;
    height           : 50px;

    &:focus {
        box-shadow : none !important;
    }

    #bill-modal & {
        background-color : $gray;
    }
}

.font-weight-demi {
    font-weight : 600;
}

.user-status {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    column-gap     : 10px;
}

.user-status-red {
    width         : 19px;
    height        : 19px;
    border-radius : 50%;
    background    : #DB001A;
}

.user-status-green {
    width         : 19px;
    height        : 19px;
    border-radius : 50%;
    background    : #5CC027;
}

.user-package-wrapper {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    column-gap     : 20px;
    line-height    : 1.1;
}

.bg-gray-dark {
    background : $gray-dark;
}

.customer-filter-wrapper {
    display               : grid;
    grid-template-columns : 35px 1fr;
    column-gap            : 11px;
    align-items           : center;
    padding               : 10px 30px;

    input, select {
        height        : 30px;
        font-size     : 20px;
        padding       : 0 15px;
        line-height   : 1;
        border-radius : 5px;
        border        : none;

        &::placeholder {
            color : $body-color;
        }
    }
}

.customer-filter-inner {
    display               : grid;
    grid-template-columns : 1fr 1fr 1fr 1fr;
    gap                   : 11px;

    @include media-breakpoint-down(xl) {
        grid-template-columns : 1fr 1fr;
    }
}

.customer-filter-search-wrapper {
    position : relative;

    i {
        position  : absolute;
        right     : 15px;
        top       : 50%;
        transform : translateY(-50%);
        font-size : 16px;
        color     : $secondary;
    }
}

.customer-view-title-section {
    display     : block;
    font-size   : 26px;
    font-weight : 600;
}

.customer-view-title {
    display       : block;
    font-size     : 20px;
    font-weight   : 600;
    margin-top    : 15px;
    margin-bottom : 10px;
}

.customer-view-person,
.customer-view-mail {
    display     : flex;
    align-items : center;
    line-height : 1;
}

.customer-view-payment-icon {
    width : 80px;
}

.box-wrapper-max-height {
    height : 100%;
}

.header-quicklink-wrapper {
    display        : flex;
    flex-direction : row;
    column-gap     : 50px;

    a {
        color          : $body-color;
        display        : flex;
        flex-direction : row;
        align-items    : center;

        &:hover {
            color : $body-color;
        }
    }

    i {
        color        : $secondary;
        font-size    : 14px;
        margin-right : 8px;
    }
}

.customer-additional-package-wrapper {
    background      : $white;
    border-radius   : 5px;
    height          : 50px;
    display         : flex;
    flex-direction  : row;
    align-items     : center;
    justify-content : space-between;
    padding         : 0 21px;
    transition      : all .3s;
    cursor          : pointer;

    &.disabled {
        cursor           : initial;
        background-color : #e9ecef;
    }

    &.active {
        background : $secondary;
        color      : $white;

        i {
            color : $white !important;
        }
    }

    i {
        margin-right : 5px;
        transition   : color .3s;
    }
}

.customer-additional-package-wrapper-right {
    display        : flex;
    flex-direction : row;
    column-gap     : 40px;
}

.monitoring-wrapper {
    border        : 2px solid $black;
    border-radius : 10px;
    display       : flex;
    align-items   : center;
    margin-bottom : 30px;
}

.monitoring-icon {
    font-weight      : 700;
    color            : $white;
    background-color : $black;
    display          : flex;
    align-items      : center;
    justify-content  : center;
    align-self       : stretch;
    min-width        : 56px;
    font-size        : 35px;
}

.monitoring-name {
    padding-top    : 17px;
    padding-bottom : 17px;
    padding-left   : 30px;
    line-height    : 1.3;
    flex-grow      : 1;
}

.monitoring-price {
    padding-right : 30px;
}

.monitoring-action {
    font-size                  : 17px;
    min-width                  : 90px;
    display                    : flex;
    justify-content            : center;
    align-items                : center;
    align-self                 : stretch;
    background                 : $secondary;
    flex-direction             : column;
    position                   : relative;
    color                      : $white;
    border-top-right-radius    : 10px;
    border-bottom-right-radius : 10px;

    .current-package & {
        background : $green;
    }

    &.down-action {
        &:before {
            content : url("../img/downgrade.svg");
        }
    }

    &.up-action {
        &:before {
            content : url("../img/upgrade.svg");
        }
    }

    a {
        color    : $white;
        position : static;

        &:after {
            height     : unset;
            width      : unset;
            background : unset;
        }
    }
}

.cart-product-wrapper {
    border-radius : 10px;
    background    : $gray;
    margin-bottom : 24px;
    padding       : 26px;
}

.cart-product-item {
    display               : grid;
    grid-template-columns : 310px 1fr 190px 150px 15px;
}

.cart-product-item-icon,
.cart-product-item-content,
.cart-product-item-price-delete {
    margin-top : 34px;
}

.cart-product-headline,
.cart-product-item-name {
    font-size   : 26px;
    font-weight : 700;
}


.cart-product-item-price-monthly,
.cart-product-item-price-yearly {
    font-weight : 700;
}

.cart-product-item-price-input,
.cart-product-item-price {
    color      : #A0A0A0;
    transition : color .3s;

    &.checked {
        color : #000;
    }
}

.cart-product-item-price-input {
    &.checked {
        color : #000;
    }
}

.cart-product-item-price {
    .cart-product-item-price-input.checked + & {
        color : #000;
    }
}

.cart-product-item-price-delete {
    color  : $secondary;
    cursor : pointer;
}

.cart-product-item-icon {
    display     : flex;
    align-items : flex-start;
}

.cart-product-item-icon-content {
    border-radius   : 10px;
    background      : $secondary;
    color           : $white;
    font-size       : 26px;
    font-weight     : 700;
    width           : 40px;
    height          : 40px;
    display         : flex;
    align-items     : center;
    justify-content : center;

    .cart-product-package & {
        font-size     : 20px;
        height        : 34px;
        width         : unset;
        padding-left  : 16px;
        padding-right : 16px;
    }

    .cart-product-additional & {
        border-radius : unset;
        background    : unset;
        color         : $secondary;
        font-size     : 39px;
    }
}

.cart-price-wrapper {
    border-radius : 10px;
    background    : $gray;
    padding       : 26px 24px;
}

.cart-price-headline {
    text-transform : uppercase;
    font-weight    : 700;
}

.cart-price-item {
    display         : flex;
    justify-content : space-between;
    margin          : 15px 0;
}

.cart-price-item-total {
    font-weight : 700;
}

.cart-warning-wrapper {
    border        : 3px solid #E30613;
    background    : rgba(#F7CECE, 0.35);
    padding       : 18px 30px;
    border-radius : 10px;
}

.cart-warning-headline {
    font-size      : 26px;
    font-weight    : 700;
    text-transform : uppercase;
}

.cart-warning-text {
    line-height   : 1.25;
    margin-bottom : 10px;
    padding-right : 100px;
}

.loading-overlay {
    left       : 0;
    top        : 0;
    width      : 100%;
    height     : 100%;
    position   : fixed;
    visibility : hidden;
    opacity    : 0;
    transition : opacity .3s, visibility .3s;
    z-index    : 1000;

    &.visible {
        opacity    : 1;
        visibility : visible;
    }

    .spinner {
        width            : 75px;
        height           : 75px;
        display          : inline-block;
        border-width     : 2px;
        border-color     : rgba(255, 255, 255, .3);
        border-top-color : $black;
        animation        : spin 1s infinite linear;
        border-radius    : 100%;
        border-style     : solid;
    }

    @keyframes spin {
        100% {
            transform : rotate(360deg);
        }
    }
}

.loading-overlay-inner {
    left       : 0;
    top        : 0;
    width      : 100%;
    height     : 100%;
    position   : absolute;
    background : #222;
    opacity    : .5;
}

.loading-overlay-content {
    left      : 50%;
    position  : absolute;
    top       : 50%;
    transform : translate(-50%, -50%);
}

.checkout-position-headline {
    font-weight     : 700;
    text-transform  : uppercase;
    margin-bottom   : 42px;
    display         : flex;
    align-items     : center;
    justify-content : space-between;

    a {
        color : $secondary;
    }
}

.checkout-position-item {
    display         : flex;
    justify-content : space-between;
    margin          : 15px 0;
}

.checkout-position-price {
    font-weight : 700;
    text-align  : right;
    padding-top : 15px;
    margin-top  : 15px;
    border-top  : 1px solid #707070;
}

.input-icon {
    position : relative;
}

.input-icon .form-control:not(:last-child),
.input-icon .form-select:not(:last-child) {
    padding-right : 2.5rem;
}

.input-icon-addon {
    position        : absolute;
    top             : 0;
    bottom          : 0;
    left            : 0;
    display         : flex;
    align-items     : center;
    justify-content : center;
    min-width       : 2.5rem;
    color           : var(--tblr-icon-color);
    pointer-events  : none;
    font-size       : 1.2em;
}

.input-icon-addon:last-child {
    right : 0;
    left  : auto;
}

.datepicker {
    + .input-icon-addon {
        left  : auto;
        right : 0;
    }

    .was-validated & {
        &:invalid,
        &:valid {
            background-position : right 36px center, center right 2.25rem
        }
    }
}
